import React from 'react'
import { NavLink } from 'react-router-dom';
import { cubicBezier, motion } from "framer-motion"

export default function Header() {
  return (
    <header>

    <div
    className="text" >
        <h1>Refresh your Room and Make Awesome Interior Design With Us!</h1>
        <p>2024 yil 20 fevralda asos solingan Dominant Soft Development IT Company - bu sizning barcha IT sohasidagi muammolariga yechim topadigan jamoangiz. Bizning kompaniyamiz tez rivojlanayotgan va innovatsiyalarga tayyor bo‘lgan misli ko‘rilmagan kuchli mutaxassislar jamoasidan iborat</p>
        <NavLink to="/contact">Biz bilan boshlaing <i class="fa-solid fa-arrow-up-right-from-square"></i></NavLink>
    </div>

     </header> 
   
  )
}
