import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from "../../media/logo.png"

function Nav() {

  return (
    <>
<nav>
    
    <div className="logo">
        <a href='/'><img src={logo}/></a>
    </div>
    <div className="menu__bar" >
        <ul>
            <li id="joq"><NavLink to="/">Biz Xaqimizda</NavLink></li>
            {/* <li id="joq"><NavLink to="/about">About us</NavLink></li> */}
            <li id="joq"><NavLink to="/service">Xizmatlar</NavLink></li>
            <li id="joq"><NavLink to="/contact">Bog'lanish</NavLink></li>
           
        </ul>
    </div>
    <div className="hum">
        <button onClick={showBar}><i className="fa-solid fa-bars"></i></button>
    </div> 
    {/* <div className="til">
                    <select name="" id="">
                        <option value="uz">Uz</option>
                       
                    </select>
    </div>   */}

</nav>

<div id='bar' className='active-bar'>
<button onClick={dontShow}>X</button>
<ul>
            <li id='men' onClick={dontShow}><NavLink to="/">Home</NavLink></li>
            <li id='men' onClick={dontShow}><NavLink to="/about">About us</NavLink></li>
            <li id='men' onClick={dontShow}><NavLink to="/service">Services</NavLink></li>
            <li id='men' onClick={dontShow}><NavLink to="/contact">Contact</NavLink></li>
</ul>
<ul id='icons'>
            <li><a href=""><i className="fa-brands fa-twitter"></i></a></li>
            <li><a href=""><i className="fa-brands fa-square-instagram"></i></a></li>
            <li><a href=""><i className="fa-brands fa-telegram"></i></a></li>
            <li><a href=""><i className="fa-brands fa-linkedin"></i></a></li>
</ul>
</div>
</>

  )
}
function dontShow(){
    let menuBar = document.getElementById("bar")
    menuBar.classList.add("active-bar")
}
function showBar(){
    let menuBar = document.getElementById("bar")
    menuBar.classList.remove('active-bar')
}


export default Nav