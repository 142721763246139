import React from 'react'
import { cubicBezier, motion } from "framer-motion"

import "./servies.css"


function Services() {
  return (
<section>
    <div className="solution">
    <h1>Xizmatlar</h1>
    <div className="solution__card">
        <div 
      
        className="item"  >
            <h2>Web development</h2>
            <p>Biz korporativ veb-saytlardan veb-ilovalarga qadar yuqori sifatli veb-saytlarni ishlab chiqamiz</p>
        </div>
        <div
      
        className="item" >
            <h2>UI&UX</h2>
            <p>Loyihaning aql xaritasini ishlab chiqish va uning arxitekturasi asosida optimal echim bilan loyihalash</p>
        </div>
        <div 
  
        className="item" >
            <h2>CRM systems</h2>
            <p>Biznes va jarayonlarni avtomatlashtirish, qog'oz ishlarini 100% ga kamaytiradigan elektron boshqaruv tizimlarini rivojlantirish%</p>
  
        </div>
        <div 
  
        className="item">
            <h2>Mobile apps</h2>
            <p>Har qanday murakkablikdagi mobil ilovalarni ishlab chiqish va texnik qo'llab-quvvatlash</p>
        </div>
    </div>
    </div>
    </section>

  )



}






export default Services