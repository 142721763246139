import React from 'react'

import logo from "../../media/logo.png"

export default function Footer() {
  return (
    <footer>
    <div className="left__foot">
        <div className="logo">
            <img src={logo} />
        </div>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro quaerat voluptatum alias minima impedit aliquid voluptas laborum eos at excepturi?</p>
        <p>© 2024 Lorem ipsum dolor sit amet.</p>

    </div>
    <div className="r__foot">
        <ul>
            <li><a href=""><i className="fa-brands fa-twitter"></i></a></li>
            <li><a href=""><i className="fa-brands fa-square-instagram"></i></a></li>
            <li><a href=""><i className="fa-brands fa-telegram"></i></a></li>
            <li><a href=""><i className="fa-brands fa-linkedin"></i></a></li>
        </ul>
    </div>
</footer>
  )
}
