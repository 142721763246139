import React from 'react'
import Header from "../components/header/Header"
import Services from '../components/services/Services'
import About from '../components/about_us/About'
import Contact from '../components/contact/Contact'

export default function Home_page() {
  return (
    <>
      
    <Header />
    <About />
    <Services />
    <Contact />
    </>

  )
}
