import React from 'react'
import { cubicBezier, motion } from "framer-motion"
import Team from '../ourteam/Team'

export default function About() {
  return (
    <>
    <section>
    <div 
   
    
    className="about__us">
    <h1>Bizning Jamoamiz:</h1>
    <p>Bizning kompaniyamiz, o‘z ishiga fidoyili qiziqadigan 12 ta tajribali IT mutaxassislardan iborat. Har birimiz o‘z sohasi bo‘yicha keng qamrovli bilim va tajribaga egamiz. Bizning maqsadimiz - mijozlarga yuqori sifatli xizmatlar ko‘rsatish va ularning ehtiyojlarini qondirishdir.</p>
    <h1>Biz nimalar qilamiz?</h1>
    <p>Dominant Soft Development IT Company quyidagi xizmatlarni taklif etadi:
<br/><br/>
- Veb-saytlar ishlab chiqish va dizayni: Responsive va user-friendly saytlar yaratish.
<br/>
- Mobil ilovalar ishlab chiqish: Ios va Android platformalar uchun ilovalarni yaratish.
<br/>
- Tizim integratsiyasi: Korxonalarning mavjud dasturiy ta’minotlari bilan integratsiya qilish.
<br/>
- Kiberxavfsizlik: Dasturlar va tizimlaringizni xavfsizligini ta’minlash.
<br/>
- IT maslahat xizmatlari: Texnologiya bo‘yicha konsultatsiya va biznesingiz uchun eng yaxshi yechimlarni topish.</p>
<h1>Nega bizni tanlashingiz kerak?</h1>
<p>
- Professionalizm: Bizning jamoamiz har bir loyihaga yuksak mahorat va professionallik bilan yondashadi.
<br/>
- Shaxsiy yondashuv: Har bir mijozning ehtiyojlariga mos individual yechimlar taklif qilamiz.
<br/>
- Innovatsiya: Yangi texnologiyalarni izlab va ulardan foydalanib, eng yuqori sifatni ta’minlaymiz.
<br/>
- Texnik qo‘llab-quvvatlash: Xizmatga qo‘shganimizdan so‘ng ham, biz sizga 24/7 qo‘llab-quvvatlashni taqdim etamiz.
</p>
    {/* <div 
    className='youtube'>
    <iframe
      src="https://www.youtube.com/embed/tY4NX2-vKQw?si=4V6E0yolsv1FSZsk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div> */}
    </div>
    <Team />
    </section>
    </>
  )
}
