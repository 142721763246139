import React from 'react'
import teamimg from './ss.jpg'
import "./team.css"
import { cubicBezier, motion } from "framer-motion"

export default function () {
  return (
    <div>
   <div class="our__team">
           <div class="para"> 
            <h1>Bizning Jamoamiz</h1>
            <p>Biz nima qilayotganimizga ishtiyoqli va mijozlarimiz uchun eng yaxshi natijalarni berishga bag'ishlangan dinamik shaxslar guruhimiz.</p>
           </div>
            {/* <div class="card__team">
                <motion.div class="item__team"
                      initial={{  opacity:0,y:70}}
                      whileInView={{  opacity:1,y:0,  }}
                      transition={{duration:1.2}}
                      viewport={{once:false ,amount:.5 }}
                >
                    <div class="team__photo">
                        <img src={teamimg} alt="" /> 
                    </div>
                    <div class="team__text">
                        <h2>Tom Jakh</h2>
                        <p>Co-Founder / CEO</p>
                    </div>
                </motion.div>
                <motion.div class="item__team"
                      initial={{  opacity:0,y:70}}
                      whileInView={{  opacity:1,y:0,  }}
                      transition={{duration:1.2}}
                      viewport={{once:false ,amount:.5 }}
                >
                    <div class="team__photo">
                        <img src={teamimg} alt=""/> 
                    </div>
                    <div class="team__text">
                        <h2>Dries Vincent</h2>
                        <p>Business Relations</p>
                    </div>
                </motion.div>
                <motion.div class="item__team"
                      initial={{  opacity:0,y:70}}
                      whileInView={{  opacity:1,y:0,  }}
                      transition={{duration:1.2}}
                      viewport={{once:false ,amount:.5 }}
                >
                    <div class="team__photo">
                        <img src={teamimg} alt=""/> 
                    </div>
                    <div class="team__text">
                        <h2>Lindsay Walton</h2>
                        <p>Front-end Developer</p>
                    </div>
                </motion.div>
                <motion.div class="item__team"
                      initial={{  opacity:0,y:70}}
                      whileInView={{  opacity:1,y:0,  }}
                      transition={{duration:1.2}}
                      viewport={{once:false ,amount:.5 }}
                >
                    <div class="team__photo">
                        <img src={teamimg} alt=""/> 
                    </div>
                    <div class="team__text">
                        <h2>Courtney Henry</h2>
                        <p>Designer</p>
                    </div>
                </motion.div>
                <motion.div class="item__team"
                      initial={{  opacity:0,y:70}}
                      whileInView={{  opacity:1,y:0,  }}
                      transition={{duration:1.2}}
                      viewport={{once:false ,amount:.5 }}
                >
                    <div class="team__photo">
                        <img src={teamimg} alt=""/> 
                    </div>
                    <div class="team__text">
                        <h2>Tom Cook</h2>
                        <p>Director of Product</p>
                    </div>
                </motion.div>
                <motion.div class="item__team"
                      initial={{  opacity:0,y:70}}
                      whileInView={{  opacity:1,y:0,  }}
                      transition={{duration:1.2}}
                      viewport={{once:false ,amount:.5 }}
                >
                    <div class="team__photo">
                        <img src={teamimg} alt=""/> 
                    </div>
                    <div class="team__text">
                        <h2>Whitney Francis</h2>
                        <p>Copywriter</p>
                    </div>
                </motion.div>
                <motion.div class="item__team"
                      initial={{  opacity:0,y:70}}
                      whileInView={{  opacity:1,y:0,  }}
                      transition={{duration:1.2}}
                      viewport={{once:false ,amount:.5 }}
                >
                    <div class="team__photo">
                        <img src={teamimg} alt=""/> 
                    </div>
                    <div class="team__text">
                        <h2>Sime Till</h2>
                        <p>Senior Developer</p>
                    </div>
                </motion.div>
                <motion.div class="item__team"
                      initial={{  opacity:0,y:70}}
                      whileInView={{  opacity:1,y:0,  }}
                      transition={{duration:1.2}}
                      viewport={{once:false ,amount:.5 }}
                >
                    <div class="team__photo">
                        <img src={teamimg} alt=""/> 
                    </div>
                    <div class="team__text">
                        <h2>Ales Ktit</h2>
                        <p>Junior Copywriter</p>
                    </div>
                </motion.div>
                <motion.div class="item__team"
                      initial={{  opacity:0,y:70}}
                      whileInView={{  opacity:1,y:0,  }}
                      transition={{duration:1.2}}
                      viewport={{once:false ,amount:.5 }}
                >
                    <div class="team__photo">
                        <img src={teamimg} alt=""/> 
                    </div>
                    <div class="team__text">
                        <h2>Meelde Gelo</h2>
                        <p>Partner, Creative</p>
                    </div>
                </motion.div>
                <motion.div class="item__team"
                      initial={{  opacity:0,y:70}}
                      whileInView={{  opacity:1,y:0,  }}
                      transition={{duration:1.2}}
                      viewport={{once:false ,amount:.5 }}
                >
                    <div class="team__photo">
                        <img src={teamimg} alt=""/> 
                    </div>
                    <div class="team__text">
                        <h2>Benjamin Russel</h2>
                        <p>VP, User Experience</p>
                    </div>
                </motion.div>
            </div> */}
        </div>
    </div>
  )
}
