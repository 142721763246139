import React from 'react'

import { cubicBezier, } from "framer-motion"


export default function Contact() {
  return (
    <section>
        
    <h1 id='contact_us_h1'>Contact</h1>
    <div className="contact__mas">
    <div
    className="contact__mas__l">
        <div className="my__contacts">
            <div className="icon__e">
                <i className="fa-solid fa-envelope"></i>
            </div>
            <div className="text__c">
               <h3>Email:</h3>
               <a href="emailto:info@dominantsoft.com">info@dominantsoft.com</a>
            </div>
        </div>
        <div className="my__contacts">
            <div className="icon__e">
                <i className="fa-solid fa-phone"></i>
            </div>
            <div className="text__c">
               <h3>Call:</h3>
               <a href="tel:+998 99 468 49 19">+998 99 468 49 19</a>
            </div>
        </div>
        <div className="my__contacts">
            <div className="icon__e">
                <i className="fa-solid fa-location-dot"></i>
            </div>
            <div className="text__c">
               <h3>Location:</h3>
               <a href="">Tashkent, Uzbekistan</a>
            </div>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d191885.59854963733!2d69.11455580652715!3d41.282479926957784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8b0cc379e9c3%3A0xa5a9323b4aa5cb98!2sTashkent%2C%20Uzbekistan!5e0!3m2!1sen!2s!4v1706948630226!5m2!1sen!2s" width="100%" height="190px"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <div 

    className="contact__mas__r" >
                <form action="">
                    <input type="text" placeholder="Name" />
                    <input type="email" placeholder="Email" />
                    <textarea name="" placeholder="Your massage" id="" cols="30" rows="10"></textarea>
                    <button>Yuborish</button>
                </form>
    </div>
    </div>
  </section>
  )
}
