import React from 'react';
import './App.css';
import Nav from './components/nav/Nav' ;
import { Route, Routes} from 'react-router-dom';
import Home_page from './pages/Home_page';
import About_page from './pages/About_page';
import Services_page from './pages/Services_page';
import Contact_page from './pages/Contact_page';
import Footer from './components/fooder/Footer';





function App() {



  return (
    <div> 
    <div className="container">
    <Nav />  
    <Routes>
      <Route path='/' element={<Home_page />} />
      <Route path='/about' element={<About_page />}/>
      <Route path='/service' element={<Services_page />}/>
      <Route path='/contact' element={<Contact_page />}/>
    </Routes>
    </div>
    <Footer />
    </div> 
  );
}




export default App;




